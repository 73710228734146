import { genMuiThemes } from 'clnt-common';

const ltp    = '#fffffa';   //eeeefc';   //'#e6ecfc';    // light color with red tint - nav bar bg color
const lts    = '#8a2c2c';    // button bg in light mode (darker purple) - secondary / also used in the manifest.webmanifest
const ltpnl  = '#fffff0';   //eeeefc';    // light color with darker red tint for panel - 22 biggest... section bg color
const ltcpnl = '#fffff0';   //eeeeff';    // light content panel -  (The same is used in manifest.webmanifest)

const dkp    = '#0e1a0c';  //'#e6ecfc';    // '#121212',
const dks    = '#c53f3e';    // button bg in dark mode (lighter purple) - secondary
const dkpnl  = '#0e1a0c';    // compliment to ltpnl
const dkcpnl = '#0e1a00';    // dark content panel

const options = {
  fontHeader :      'Albra',
  fontText :        'Poppins',

  primaryLight:     ltp, // '#e6ecfc', //'#ededed',
  secondaryLight:   dks, //'#0b2480',
  panelBgLight:     ltpnl, //'#d9e2f7',
  backpanelBgLight: ltp, //'#0d238a',
  contentpanelBgLight: ltcpnl,

  primaryDark:      dkp, // '#121212',
  secondaryDark:    lts, //'#f9b0a6',
  panelBgDark:      dkpnl, //'#121212',
  backpanelBgDark:  dkp, //'#e6ecfc',
  contentpanelBgDark:  dkcpnl,

  pagePadding : 1,
  pageGap : 0,

  // For use within pages.ts (rendered via PageContentFormat.jsx)
  // 5 sets of areas.
  areaLight: [{ panel: '', border: '#cccccc' },  // about
              { panel: '', border: '#3845f3' },  // service:matrix
              { panel: '#e8eafb', border: '' },  // service:chat
              { panel: '#f5e1e0', border: '#cccffa' },  // service: *
              { panel: '', border: '' }],  
  areaDark:  [{ panel: '', border: '#f6dad9' },  
              { panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' }]  
};

export { themes };
let themes = genMuiThemes(options);
