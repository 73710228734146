import React, { lazy, Suspense } from 'react';

//import { getEnvMap } from './envVarMgr.js';
import dataUtil from '../server/dataUtil.js';
import logit from '../server/logit.js';

export { siteData };
const siteData = (getEnvVarJson, parms) => {
  let getEnvVar = getEnvVarJson;
  let { type, pagePath = '/' } = parms;
  let seoPageData = {};

  //console.log('siteData: dump params', parms);
  // usage:
  // Get routes - THIS HAD PROBLEMS TO CONSOLIDATE and hence it's still in its own file: src/routeList
  //     MAY WANT TO TRY GENERATING THE LIST inside src/routeList from this file
  //     const routes = siteData({ type: 'routeList' });
  //
  // get navigation options (headers/footers)
  //     return siteData({ type: 'headerOptions' });
  //     return siteData({ type: 'footerOptions' });
  //
  // get seoData   
  //     seoPageData: {caller, frontmatter = {}, staticDataLoadResp = null, slug = null }
  //
  // get serverData TODO
  //     

  //
  // Unable to support routeList with imports for '*.mdx' files as it complains about unable to load '.mdx' files.
  // This happens loc:devnode and loc:devvite.
  // So, we keep it in a separate file. src/routeList.js
  /*
  const routeList = [
    { path: "/",                    import: () => import("../pages/Home") , module: "../pages/Home"},
    ....
  ]
  */
  /*
  if (!getEnvVar) {
   getEnvVar = getEnvMap();
  }
  */

  let headerOptions;
  let footerOptions;
  if (type == 'headerOptions' || type == 'footerOptions') {
    // draft: true|false
    // type: page|mdx|ext|     
    // use:  hdr|bgr|dd|ftr
    // auth: y|n
    // path can also be link to a section within a page: path: '/somepage/#section
    const headerPaths = [
      //{ display: 'Home',             path: '/',                  draft: false, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
      { display: 'About',              path: '/about',             draft: false, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
      { display: 'Services',           path: '/services',          draft: false, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
      { display: 'Process',            path: '/process',           draft: false, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
      { display: 'Technology',         path: '/technology',        draft: false, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
      { display: 'Products',           path: '/products',          draft: false, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
      { display: 'Blog',               path: '/blog',              draft: true,  type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },

//    { display: 'Monitor',            path: '/app/monitor',       draft: false, type: 'page', use: ['dd','bgr'],       auth: ['y'] },

      { display: 'divider',            path: '',                   draft: false, type: 'page', use: ['bgr'],            auth: ['y','n'] },

      { display: 'My Account',         path: '/myaccount',         draft: false, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
    ];

    const footerPaths = [
      { display: 'About',              path: '/about',             draft: false, type: 'mdx', use: ['ftr','bgr'],      auth: ['y', 'n'] },
      { display: 'Privacy',            path: '/privacy',           draft: false, type: 'mdx', use: ['ftr','bgr'],      auth: ['y', 'n'] },
      { display: 'Terms & Conditions', path: '/termsandconditions', draft: false, type: 'mdx', use: ['ftr','bgr'],      auth: ['y', 'n'] },
      { display: 'Contact Us',         path:getEnvVar.contactUsUrl,draft: false, type: 'ext', use: ['ftr','bgr'],      auth: ['y', 'n'] },
    ];

    headerOptions = {
      appLogoFilePath: getEnvVar.APP_LOGO_FILE_PATH,
      logoWidth:  48, // 159,  // 175,   // manually set
      logoHeight: 48,  // 63,   // 70,   // manually set
      logoText: getEnvVar.LOGO_TEXT,   // logoText is optional. If supplied it'll be listed
      logoTextColor: '#ff0000',
      logoTextType: 'rainbow',   // 'plain' | 'rainbow' 
      logoRainbowColor: '#07bef5',
      logoFont: 'Bauhaus',
      appLogoHref: '/',
      headerPaths: headerPaths,
      activeStyle: '2px solid red',
      inactiveStyle: '2px solid transparent',
      navBarHeightOffset: '56px',   // Due to "null " text just after <body> tag. Don't know where it's from
    };

    footerOptions = {
      footerPaths: footerPaths,
      hoverTextColor: '#ff0000',
    }

    /* Future usage for page context menu - social share - will it work in iPad?

       const menuPaths = [
       'Context Menu', 'A', 'B'
       ];

       export const menuOptions = {
       menuPaths: menuPaths,
       }
     */
  }

  //
  // routes needing data
  //
  // note: All listed routes' component requires code change - access allSrvrData
  if (type == 'routesNeedingData') {
    //return ['/', '/recent', '/blog'];
    return [];
  }

  //
  // routes needing Adverts
  //
  if (type == 'routesAdSpecs') {
    const Adverts = lazy(() => import('../area/Adverts'));
    return [
      { page: '/products',
	ad: Adverts,
	options: { left: false, right: true, bottom: false }
      },
    ];
  }
  
  if (type == 'seoData' && pagePath) {
    let title, description, type, path, keywords;

    keywords = ['custom', 'software', 'react'];

    /*
    // Strip out '/pages' from '/pages/xxx'
    // doesn't work for the error page: '/renderer/_error'
    const callerWOPages = caller.substr(6);
    const pos = callerWOPages.lastIndexOf('/')
    let callerWOIndex = callerWOPages;
    if (pos !== -1) {
      callerWOIndex = callerWOPages.substr(0, pos);
    }
    */

    //console.log('seoSitePageData caller ', caller);
    //console.log('seoSitePageData callerWOPages', callerWOPages);
    //console.log('seoSitePageData emert', emert);

    /* A Template for seoPageData can be reviewed at recalls/src/data/seoSitePageData.js
     */

    switch (pagePath) {
    case '/fourofour':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Sorry. The page you are looking for is not found. It may have moved!',
	keywords: ['consumer', 'institution', 'supplier'],
	path: pagePath,
	slogan: '',
	title: '404 - Page not found.',
      };
      break;
    case '/about':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'One of the top rated software development company.',
	keywords: keywords,
	path: pagePath,
	slogan: '',
	title: 'Custom Software Developers',
      };
      break;
    case '/blog':
      // This seo is for the blog's main landing page   (not for the individual blogs)
      seoPageData = {
	type: 'BlogIndexPage',
	personSeoCode: 'principal',
	dateModified: Date(),
	datePublished: Date(),
	description: 'All posts written by Kripa staff addressing technology and automation.',
	keywords: ['products', 'food', 'meat', 'vehicles', 'drugs', 'veterinary'],
	path: pagePath,
	slogan: '',
	title: 'Technology News and Trends Blog',
      };
      break;
    case '/blog/?id':
      seoPageData = {
	type: 'BlogPage',
	personSeoCode: 'principal',
	dateModified: Date(),
	datePublished: Date(),
	description: '', //frontmatter.excerpt,
	keywords: ['products', 'food', 'meat', 'vehicles', 'drugs', 'veterinary'],
	path: pagePath,
	pic: '', //frontmatter.pic,   // '/images/mdxpages/chef-boyardee.jpg',
	slogan: '',
	title: '', //frontmatter.title,
      };
      break;
    case '/index.html':
    case '/':
      seoPageData = {
	type: 'WebIndexPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Custom software development services from top rated software development company. Helping modern business leverage OSS solutions.',
	keywords: keywords,
	path: pagePath,
	slogan: 'Building Exceptional Solutions',
	title: 'Custom Software Developers',
      };
      break;
    case '/privacy':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Website privacy policy',
	keywords: ['consumer', 'institution', 'supplier'],
	path: pagePath,
	slogan: '',
	title: 'Website privacy policy',
      };
      break;
    case '/process':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Our software develpment process.',
	keywords: keywords,
	path: pagePath,
	slogan: '',
	title: 'Process: Experience, Methodology & Team Work',
      };
      break;
    case '/products':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Software products developed by Kripa.',
	keywords: keywords,
	path: pagePath,
	slogan: '',
	title: 'Solutions: Recalls, Chat Messenger & No Code apps',
      };
      break;
    case '/myaccount':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Configure and manage your account, plans and personal preferences',
	keywords: ['signin', 'signout', 'plans', 'preferences'],
	path: pagePath,
	slogan: '',
	title: 'My Account: Manage access, plans and preferences',
      };
      break;
    case '/services':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Digital technology services partner for today\'s demanding and progresive businesses. ',
	keywords: ['', '', '', '', ''],
	path: pagePath,
	slogan: '',
	title: 'Tech Strategy, Arch, Web & Mobile Apps and Support',
      };
      break;
    case '/technology':
      seoPageData = {
	type: 'WebPage', // 'BlogIndexPage', 'BlogPage', 'MDXPage', 'WebPage', 'WebIndexPage', 'AppPage'
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Technology used for software develpment.',
	keywords: keywords,
	path: pagePath,
	slogan: '',
	title: 'Tech: Tested And Proven OSS Platforms & Frameworks',
      };
      break;
    case '/termsandconditions':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Our simple and privacy friendly terms and conditions.',
	keywords: ['consumer', 'institution', 'supplier'],
	path: pagePath,
	slogan: '',
	title: 'Website terms and conditions policy',
      };
      break;
    default:
      if (pagePath.indexOf('/blog') !== -1) {
	seoPageData = {
	  type: 'BlogPage',
	  personSeoCode: 'principal',
	  dateModified: Date(),
	  datePublished: Date(),
	  description: '', //frontmatter.excerpt,
	  keywords: ['products', 'food', 'meat', 'vehicles', 'drugs', 'veterinary'],
	  path: pagePath,
	  pic: '', //frontmatter.pic,   // '/images/mdxpages/chef-boyardee.jpg',
	  slogan: '',
	  title: '', //frontmatter.title,
	};
      }
      else {
	seoPageData = {
	  type: 'WebPage',
	  personSeoCode: 'principal',
	  dateModified: '',
	  datePublished: '',
	  description: 'This page has no description',
	  keywords: ['consumer', 'institution', 'supplier'],
	  path: pagePath,
	  slogan: '',
	  title: 'This page has no title',
	};
      }
      break;
      /*
	 case '/partners':
	 seoPageData = {
	 type: 'WebPage', // 'BlogIndexPage', 'BlogPage', 'MDXPage', 'WebPage', 'WebIndexPage', 'AppPage'
	 personSeoCode: 'principal',
	 dateModified: '',
	 datePublished: '',
	 description: 'Partner with us - The top rated software development company.',
	 keywords: keywords,
	 path: pagePath,
	 slogan: '',
	 title: 'Partner To Develop Smart Tech and Empower Business',
	 };
	 break;
	 case '/portfolio':
	 seoPageData = {
	 type: 'WebPage', // 'BlogIndexPage', 'BlogPage', 'MDXPage', 'WebPage', 'WebIndexPage', 'AppPage'
	 personSeoCode: 'principal',
	 dateModified: '',
	 datePublished: '',
	 description: 'Sample of projects we developed to help business grow',
	 keywords: keywords,
	 path: pagePath,
	 slogan: '',
	 title: 'Portfolio Of Software Products',
	 };
	 break;
       */
    } // switch

    seoPageData['isCanonical'] = true;

  }









  
  
  //if (type == 'routeList') {
  //  return routeList;
  //}
  //else
  if (type == 'headerOptions') {
    return headerOptions;
  }
  else
    if (type == 'footerOptions') {
      return footerOptions;
    }
  else
    if (type == 'seoData') {
      return seoPageData;
    }


};
